define('hours-synthesizer/helpers/is-not', ['exports', 'ember-bootstrap/helpers/is-not'], function (exports, _isNot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isNot.default;
    }
  });
  Object.defineProperty(exports, 'isNot', {
    enumerable: true,
    get: function () {
      return _isNot.isNot;
    }
  });
});