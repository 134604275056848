define('hours-synthesizer/helpers/read-path', ['exports', 'ember-bootstrap/helpers/read-path'], function (exports, _readPath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _readPath.default;
    }
  });
  Object.defineProperty(exports, 'readPath', {
    enumerable: true,
    get: function () {
      return _readPath.readPath;
    }
  });
});